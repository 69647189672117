import React from "react";
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import {Busqueda} from "../containers/Busqueda.jsx";

function router(){
    return(
        <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Busqueda/>}/>
        </Routes>
        </BrowserRouter>
    );
}
export default router;