import React, { useEffect, useState } from "react";
import { Container, Card, CardGroup } from "react-bootstrap";
import Header from "../components/Header";
import Footie from "../components/Footer";
import Ordbusca from "../components/ordBusca";
import DatosOrden from "../components/datos";

export const Busqueda = () => {
  const [verDatos, setVerDatos] = useState(false);
  const [valores, setValores] = useState([]);

  const handleChange = ({ target }) => {
    setValores({
      ...valores,
      [target.name]: target.value,
    });
    console.log(target);
  };
  return (
    <Container>
      <CardGroup>
        <Card>
          <Card.Body>
            <Ordbusca datos={handleChange} ver={setVerDatos} />
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup>
        <Card>
          <Card.Body>
            {verDatos ? <DatosOrden datos={valores} /> : null}
          </Card.Body>
        </Card>
      </CardGroup>

      <DatosOrden />
    </Container>
  );
};
