import React, { useState } from "react";
import { Container, Form, FloatingLabel, Button } from "react-bootstrap";

export const Ordbusca = ({ datos, ver }) => {
  const [state, setState] = useState([]);

  const Buscar = async (e) => {
    e.preventDefault();
    ver(true);
    // console.log(e);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Form>
        <Form.Group className="mb-3">
          <FloatingLabel
            controlId="floatingInput"
            label="No de orden"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="ord"
              placeholder="No de orden"
              onChange={datos}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Telefono"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="tele"
              onChange={datos}
              placeholder="Telefono"
              required
            />
          </FloatingLabel>
        </Form.Group>

        <button className="btn btn-primary" onClick={Buscar}>
          Buscar orden
        </button>
      </Form>
    </Container>
  );
};
export default Ordbusca;
