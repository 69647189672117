import React, { useState, useEffect } from "react";
import Ima1 from "../images/p1.png";
import Ima2 from "../images/p2.png";
import Ima3 from "../images/p3.png";
import Ima4 from "../images/p4.png";
import Ima5 from "../images/p5.png";
import Ima6 from "../images/p6.png";
import Ima7 from "../images/p7.png";
import Ima8 from "../images/p8.png";
import Ima9 from "../images/p9.png";
import axios from "axios";
import Swal from "sweetalert";
import moment from "moment";
import { Container, Tab, Row, Col, ListGroup, Image } from "react-bootstrap";
export const DatosOrden = ({ datos }) => {
  //const URLSAVE = "cliente/Update";

  const [estadoAll, setEstadoAll] = useState({
    p1: false,
    p2: false,
    p3: false,
    p4: false,
    p5: false,
    p6: false,
    p7: false,
    p8: false,
    p9: false,
  });
  const [ord, setOrd] = useState();
  const [fechas, setFechas] = useState([]);

  const getData = async () => {
    var URL = "Orden/oneorder/";
    console.log(datos);
    if (datos === undefined) {
      URL = URL + 0;
    } else {
      URL = URL + datos.ord;
    }
    console.log(URL);
    const response = axios.get(URL);
    return response;
  };

  const convtoArr = async (ArrJson) => {
    //  console.log(telef !== undefined);
    if (datos === undefined) {
    } else {
      if (ArrJson.telefono === datos.tele) {
        setEstadoAll({
          p1: true,
          p2: true,
          p3: false,
          p4: false,
          p5: false,
          p6: false,
          p7: false,
          p8: false,
          p9: false,
        });

        //const fin = new Date(ArrJson.F_Disenio);
      } else {
        await Swal(
          "No autorizado",
          "No se puede mostrar la orden debido a que el telefono no es el mismo que se registró con la orden",
          "warning"
        );
        return;
      }
    }

    let fech = moment(ArrJson.fecha).format("yyyy-MM-DD HH:mm");

    let dise = moment(ArrJson.f_DISENIO).format("yyyy-MM-DD HH:mm");
    let rev = moment(ArrJson.f_REVISION).format("yyyy-MM-DD HH:mm");
    let apr = moment(ArrJson.f_APROBACION).format("yyyy-MM-DD HH:mm");
    let fin = moment(ArrJson.f_FINALIZACION).format("yyyy-MM-DD HH:mm");
    const ent = moment(ArrJson.f_ENTREGA).format("yyyy-MM-DD HH:mm");

    //  console.log(dise > fech);
    if (dise > fech) {
      setEstadoAll({
        p1: true,
        p2: true,
        p3: true,
        p4: false,
        p5: false,
        p6: false,
        p7: false,
        p8: false,
        p9: false,
      });
    }
    // console.log(rev > dise);
    if (rev > dise) {
      setEstadoAll({
        p1: true,
        p2: true,
        p3: true,
        p4: true,
        p5: false,
        p6: false,
        p7: false,
        p8: false,
        p9: false,
      });
    }
    //   console.log(apr > rev);
    if (apr > rev) {
      setEstadoAll({
        p1: true,
        p2: true,
        p3: true,
        p4: true,
        p5: true,
        p6: true,
        p7: false,
        p8: false,
        p9: false,
      });
    }
    // console.log(fin > apr);
    if (fin > apr) {
      setEstadoAll({
        p1: true,
        p2: true,
        p3: true,
        p4: true,
        p5: true,
        p6: true,
        p7: true,
        p8: false,
        p9: false,
      });
    }
    // console.log(ent > fin);
    if (ArrJson.estado === "Entregada") {
      setEstadoAll({
        p1: true,
        p2: true,
        p3: true,
        p4: true,
        p5: true,
        p6: true,
        p7: true,
        p8: true,
        p9: true,
      });
    }

    const valor = {
      F1: moment(ArrJson.fecha).format("DD/MM/yyyy"),
      F2: moment(ArrJson.fecha).format("DD/MM/yyyy"),
      F3: moment(ArrJson.f_DISENIO).format("DD/MM/yyyy"),
      F4: moment(ArrJson.f_REVISION).format("DD/MM/yyyy"),
      F5: moment(ArrJson.f_APROBACION).format("DD/MM/yyyy"),
      F6: moment(ArrJson.f_APROBACION).format("DD/MM/yyyy"),
      F7: moment(ArrJson.f_FINALIZACION).format("DD/MM/yyyy"),
      F8: moment(ArrJson.F_Entrega).format("DD/MM/yyyy"),
      F9: moment(ArrJson.f_ENTREGA).format("DD/MMM/yyyy"),
    };
    return valor;
  };

  useEffect(() => {
    //usefect body
    getData().then(async (response) => {
      console.log(response.data);
      const datos = await convtoArr(response.data);
      //console.log(datos);
      setFechas(datos);
      //hacer alggo con esa respuesta
      //  setList(response.data);
      //listade();
      //console.log(response.data);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col sm={6}>
          <ListGroup variant="flush">
            {estadoAll.p1 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima1} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Generamos tu pedido</h5>
                    <small>{fechas === undefined ? null : fechas.F1}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p2 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima2} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Diseñando</h5>
                    <small>{fechas === undefined ? null : fechas.F2}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p3 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima3} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Pedido Aprobado</h5>
                    <small>{fechas === undefined ? null : fechas.F3}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p4 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima4} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Produccion Agendada</h5>
                    <small>{fechas === undefined ? null : fechas.F4}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p5 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima5} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">En Produccion</h5>
                    <small>{fechas === undefined ? null : fechas.F5}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p6 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3 sm-2">
                    <img src={Ima6} alt="logos" height="50px" width="50px" />
                  </div>

                  <div className="col-md-9 sm-10">
                    <h5 class="mb-1">Ultimando Detalles</h5>
                    <small>{fechas === undefined ? null : fechas.F6}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p7 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima7} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Terminado y empacado</h5>
                    <small>{fechas === undefined ? null : fechas.F7}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p8 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima8} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Pago Recibido</h5>
                    <small>{fechas === undefined ? null : fechas.F8}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}

            {estadoAll.p9 ? (
              <ListGroup.Item>
                <div className="d-flex w-100 justify-content-between">
                  <div className="col-md-3">
                    <img src={Ima9} alt="logos" height="50px" width="50px" />
                  </div>
                  <div className="col-md-9">
                    <h5 class="mb-1">Entregado / En Ruta</h5>
                    <small>{fechas === undefined ? null : fechas.F9}</small>
                  </div>
                </div>
              </ListGroup.Item>
            ) : null}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
export default DatosOrden;
